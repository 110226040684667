import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import ReactWhatsapp from "react-whatsapp";

const SliderAnimation = () => {
  const [select, setSelect] = useState("");

  const selected = (item) => {
    setSelect((pre) => (item === pre ? null : item));
  };

  const message = `Hi, I'm [Your Name]. ${select ? `I'm interested in the ${select} Project` : "I'm reaching out to inquire about the packages you offer for website and mobile app development?"} Could we schedule a meeting? Please let me know your availability for a call. [Add any thing about your project].`;
  
  const content = [
    {
      id: 1,
      title: "Online Store",

      icon: (
        <svg
          data-bbox="0.953 0.702 20.401 18"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22 19"
          height="19"
          width="22"
          data-type="color"
        >
          <g>
            <path
              fill="#f52225"
              d="M2.597.702h17.171a.6.6 0 0 1 .593.509l.992 5.491a2.939 2.939 0 0 1-2.4 2.944v6.656a2.4 2.4 0 0 1-2.4 2.4h-10.8a2.4 2.4 0 0 1-2.4-2.4v-6.66a2.988 2.988 0 0 1-2.4-2.94c0-.056 1.066-5.56 1.066-5.56a.6.6 0 0 1 .578-.44Zm.018 7.338a1.624 1.624 0 0 0 1.338.462c1.306 0 2.4-1.8 2.4-1.8a2.46 2.46 0 0 0 2.393 1.8 2.495 2.495 0 0 0 2.407-1.8 2.928 2.928 0 0 0 2.4 1.8 2.928 2.928 0 0 0 2.4-1.8s1.2 1.8 2.4 1.8a1.678 1.678 0 0 0 1.8-1.8l-.9-4.8h-16.2l-.9 4.8c-.06.493.11.987.462 1.338Zm12.738 7.062h-8.4v-5.93a3.431 3.431 0 0 1-.6-.67 3.038 3.038 0 0 1-1.8 1.139v6.661a1.2 1.2 0 0 0 1.2 1.2h10.8a1.2 1.2 0 0 0 1.2-1.2v-6.67a3.158 3.158 0 0 1-1.8-1.13c-.167.253-.368.48-.6.676v5.924Zm-4.2-6.6a2.777 2.777 0 0 1-3 1.08v4.32h6v-4.32a2.769 2.769 0 0 1-3-1.08Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              data-color="1"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      id: 2,
      title: "Portfolio ",
      icon: (
        <svg
          data-bbox="0.195 0.695 20.575 18.003"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 19"
          height="19"
          width="21"
          data-type="color"
        >
          <g>
            <path
              fill="#f52225"
              d="M14.34 10.982a1.286 1.286 0 1 0 0-2.572 1.286 1.286 0 0 0 0 2.572Z"
              data-color="1"
            ></path>
            <path
              fill="#f52225"
              d="M5.339 4.552V3.266A2.572 2.572 0 0 1 7.911.695h5.144a2.572 2.572 0 0 1 2.571 2.571v1.286h3.858c.71 0 1.286.576 1.286 1.286v11.574c0 .71-.576 1.286-1.286 1.286H1.481c-.71 0-1.286-.576-1.286-1.286V5.838c0-.71.576-1.286 1.286-1.286H5.34Zm2.572-2.571c-.71 0-1.286.575-1.286 1.285v1.286h7.716V3.266c0-.71-.576-1.286-1.286-1.286H7.91ZM1.48 5.838v9.259l4.455-5.569A.9.9 0 0 1 7.3 9.48l7.322 7.932h4.862V5.838H1.481Zm0 11.574h11.39l-6.211-6.73-5.179 6.473v.257Z"
              data-color="1"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      id: 3,
      title: "Blog",
      icon: (
        <svg
          data-bbox="0.285 0.819 11.911 17.736"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13 19"
          height="19"
          width="13"
          data-type="ugc"
        >
          <g>
            <path
              stroke-width=".24"
              fill="#f52225"
              d="m11.226 9.776-3.998 7.6a.457.457 0 0 1-.404.245h-.125v-6.133a1.86 1.86 0 0 0 1.374-1.8c0-1.03-.821-1.868-1.832-1.868-1.01 0-1.833.838-1.833 1.867 0 .867.586 1.592 1.375 1.8v6.134h-.126a.455.455 0 0 1-.404-.245l-3.998-7.6a.475.475 0 0 1-.014-.414l2.152-4.809h5.695l2.151 4.81a.473.473 0 0 1-.013.413Zm-4.985.845a.925.925 0 0 1-.916-.934c0-.514.41-.933.916-.933.505 0 .916.419.916.933a.926.926 0 0 1-.916.934ZM2.117 3.385v-1.4c0-.128.103-.233.23-.233h7.788c.126 0 .23.105.23.233v1.4a.232.232 0 0 1-.23.234H2.347a.231.231 0 0 1-.23-.234Zm9.956 5.59-1.977-4.423h.04c.63 0 1.145-.522 1.145-1.167v-1.4c0-.643-.514-1.166-1.146-1.166H2.347A1.159 1.159 0 0 0 1.2 1.986v1.4c0 .645.513 1.167 1.146 1.167h.039L.409 8.975c-.178.394-.163.859.038 1.242l3.999 7.6c.239.456.704.738 1.211.738h1.167a1.37 1.37 0 0 0 1.212-.739l3.998-7.599a1.43 1.43 0 0 0 .04-1.241Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      id: 4,
      title: "Consultant",
      icon: (
        <svg
          data-bbox="0.862 0.212 20.547 17.977"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22 19"
          height="19"
          width="22"
          data-type="color"
        >
          <g>
            <path
              fill="#f52225"
              d="M6 4.064V2.78A2.568 2.568 0 0 1 8.568.212h5.136a2.568 2.568 0 0 1 2.568 2.568v1.284h3.853c.709 0 1.284.575 1.284 1.284v11.557c0 .71-.575 1.284-1.284 1.284H2.146a1.284 1.284 0 0 1-1.284-1.284V5.348c0-.709.575-1.284 1.284-1.284H6Zm1.284 0h7.704V2.78c0-.71-.575-1.284-1.284-1.284H8.568c-.71 0-1.284.575-1.284 1.284v1.284Zm12.84 1.284H2.148V9.2H6V7.916h1.284V9.2h7.704V7.916h1.284V9.2h3.853V5.348ZM2.148 10.485v6.42h17.978v-6.42h-3.853v1.284h-1.284v-1.284H7.284v1.284H6v-1.284H2.147Z"
              clip-rule="evenodd"
              fill-rule="evenodd"
              data-color="1"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      id: 5,
      title: "Service Business",
      icon: (
        <svg
          data-bbox="4.281 4 16 16"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 24"
          height="24"
          width="25"
          data-type="color"
        >
          <g>
            <path
              fill="#f52225"
              d="M15.281 12a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
              data-color="1"
            ></path>
            <path
              fill="#f52225"
              d="M11.281 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
              data-color="1"
            ></path>
            <path
              fill="#f52225"
              d="M9.281 15a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
              data-color="1"
            ></path>
            <path
              fill="#f52225"
              d="M14.281 16a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
              data-color="1"
            ></path>
            <path
              fill="#f52225"
              d="M12.281 15a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
              data-color="1"
            ></path>
            <path
              fill="#f52225"
              d="M19.281 5h-2V4h-1v1h-8V4h-1v1h-2a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Zm-2 1h2v3h-14V6h2v1h1V6h8v1h1V6Zm-12 4h14v9h-14v-9Z"
              data-color="1"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      id: 6,
      title: "Restaurant",
      icon: (
        <svg
          data-bbox="0.07 0.3 13.133 17.909"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 14 19"
          height="19"
          width="14"
          data-type="color"
        >
          <g>
            <path
              fill="#f52225"
              d="M4.846 3.882V.3H6.04v7.164c0 .659-.535 1.194-1.194 1.194v8.357c0 .66-.535 1.194-1.194 1.194H2.458c-.66 0-1.194-.535-1.194-1.194V8.658C.604 8.658.07 8.123.07 7.464V.3h1.194v3.582h1.194V.3h1.194v3.582h1.194Zm0 1.194H1.264v2.388h1.194v9.55h1.194v-9.55h1.194V5.076Zm4.775 7.172c-.02-.717-.175-1.181-.426-1.417-.522-.49-.767-1.227-.767-2.173v-5.97A2.388 2.388 0 0 1 10.815.3h1.194c.66 0 1.194.535 1.194 1.194v15.52c0 .66-.534 1.195-1.194 1.195h-1.194a1.195 1.195 0 0 1-1.194-1.194v-4.768Zm1.194 4.767h1.194V1.495h-1.194c-.659 0-1.194.534-1.194 1.193v5.97c0 .645.142 1.068.392 1.303.521.49.775 1.277.802 2.287v4.767Z"
              data-color="1"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      id: 7,
      title: "Event",
      icon: (
        <svg
          data-bbox="1 0.603 18.308 18.11"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          height="20"
          width="20"
          data-type="ugc"
        >
          <g>
            <path
              fill="#f52225"
              d="m17.852 5.934-.152.042a2.914 2.914 0 0 1-3.554-3.703l.049-.15-1.456-1.52L1 11.866l1.456 1.517.152-.042a2.914 2.914 0 0 1 3.554 3.704l-.049.15 1.456 1.518L19.308 7.452l-1.456-1.518Zm-11.14 11.12a3.444 3.444 0 0 0-4.09-4.263l-.871-.91 7.394-7.094.955.995.383-.367-.955-.995 3.195-3.066.873.91a3.444 3.444 0 0 0 4.09 4.263l.872.91-3.195 3.065-.954-.995-.383.367.954.995-7.394 7.094-.873-.91Z"
            ></path>
            <path
              fill="#f52225"
              d="m11.778 6.805-.383.367 1.224 1.275.383-.367-1.224-1.275Z"
            ></path>
            <path
              stroke-width=".589"
              stroke="#f52225"
              d="m17.852 5.934-.152.042a2.914 2.914 0 0 1-3.554-3.703l.049-.15-1.456-1.52L1 11.866l1.456 1.517.152-.042a2.914 2.914 0 0 1 3.554 3.704l-.049.15 1.456 1.518L19.308 7.452l-1.456-1.518Zm-11.14 11.12a3.444 3.444 0 0 0-4.09-4.263l-.871-.91 7.394-7.094.955.995.383-.367-.955-.995 3.195-3.066.873.91a3.444 3.444 0 0 0 4.09 4.263l.872.91-3.195 3.065-.954-.995-.383.367.954.995-7.394 7.094-.873-.91Z"
              fill="none"
            ></path>
            <path
              stroke-width=".589"
              stroke="#f52225"
              d="m11.778 6.805-.383.367 1.224 1.275.383-.367-1.224-1.275Z"
              fill="none"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      id: 8,
      title: "Other",
      icon: (
        <svg
          data-bbox="0.883 0.359 15.508 3.877"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 17 5"
          height="5"
          width="17"
          data-type="color"
        >
          <g>
            <path
              fill="#f52225"
              d="M2.821.359a1.938 1.938 0 1 1 0 3.877 1.938 1.938 0 0 1 0-3.877Zm5.816 0a1.938 1.938 0 1 1 0 3.877 1.938 1.938 0 0 1 0-3.877Zm5.815 0a1.938 1.938 0 1 1 0 3.877 1.938 1.938 0 0 1 0-3.877Zm-11.63.97a.97.97 0 1 0 0 1.938.97.97 0 0 0 0-1.939Zm5.815 0a.97.97 0 1 0 0 1.938.97.97 0 0 0 0-1.939Zm5.815 0a.97.97 0 1 0 0 1.938.97.97 0 0 0 0-1.939Z"
              data-color="1"
            ></path>
          </g>
        </svg>
      ),
    },
  ];

  return (
    //    HERO
    <div>
      <div>
        <div data-aos="fade-down" data-aos-duration="1200">
          <h2 className="home-h1">
            Create a Website and Mobile App <br /> without limits
          </h2>
          <h1 className="home-h2">
            What kind of Website and Mobile App would you like to create?
          </h1>
          <p className="home-p1">
            Build and scale with confidence. From a powerful Website and Mobile
            App builder to advanced business solutions—we’ve got you covered.
          </p>
        </div>

        <div data-aos="fade-up" data-aos-duration="1200" className="home-d2">
          <div className="home-d3">
            {content.map((item, index) => (
              <div className="home-d5" key={index}>
                {select === item.title && (
                  <div className="svg-home-d1">
                    <FaCheck size={15} />
                  </div>
                )}

                <button
                  onClick={() => {
                    selected(item.title);
                  }}
                  className="home-btn"
                >
                  {item.icon}
                  {item.title}
                </button>
              </div>
            ))}
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="1200" className="home-d4">
          <ReactWhatsapp number={"+91 7018548021"} message={message}>
            Get started
          </ReactWhatsapp>
        </div>
      </div>
    </div>
    //HERO
  );
};

export default SliderAnimation;
